const gateUrl = document.getElementsByTagName('head')[0].dataset.gate
const url=`${gateUrl}/token.json?annotations[requester]=finance`;

function update_jwt() {
  fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then(({ jwt }) => {
      document.cookie = "__Host-finance-jwt=" + jwt + "; Path=/; Secure;";
    })
    .catch(error => console.error(error))
}

update_jwt();
setInterval(update_jwt, 60*1000);
